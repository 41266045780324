@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .footer-bg {
    background-color: #f5f5ff;
  }
}
.md-RichEditor-root {
  width: 100%;
  position: inherit;
}
.md-open-button {
  padding: 6px;
}

figure.md-block-image img {
  position: relative !important;
  margin: auto !important;
}

figure.md-block-image figcaption .public-DraftStyleDefault-block {
  text-align: initial;
}

.about_count {
  background: linear-gradient(94.08deg, #00008b -15.02%, #91b3fa 133.54%);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.md-RichEditor-editor .md-side-toolbar .md-sb-button.md-add-button {
  padding: 6px !important;
}

figure.md-block-image figcaption.md-block-image-caption--empty {
  position: relative !important;
  margin-left: 45% !important;
}

@media only screen and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 0) {
  .infinite-scroll-component__outerdiv .grid-cols-1 {
    display: contents;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
h1.md-block{
  font-size: 2.5rem;
  font-weight: bold;
}

.women-power-gradient {
  height: 561px;
  left: -23px;
  bottom: 2254px;
  background: linear-gradient(73.51deg, #DCDCF5 9.15%, #F1F7FF 56.26%, #DCECFF 92.6%, #E2EAFF 95.29%);
  border-radius: 19px;
}


.JoinCommunity {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 33px;
  gap: 16px;
  width: 210px;
  height: 51px;
  left: 730px;
  bottom: 2411px;
  background: #FFFFFF;
  border: 1px solid #00008B;
  border-radius: 43px;
}

.curve {
  border-radius: 0 0 50% 50%/0 0 20% 20%;
}
